import * as echarts from 'echarts';

const componentChart = () => {

  const observeOptions = {
    root: null,
    threshold: 0.75,
  };

  const bilanzsumme = document.getElementById('bilanzsumme')
  let echartBilanzsumme;
  if (bilanzsumme) {
    echartBilanzsumme = echarts.init(bilanzsumme);

    const observeEchartBilanzsumme = new IntersectionObserver (function (entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          echartBilanzsumme.setOption({
            color: [
              '#ff6600'
            ],
            tooltip: {
              show: false,
            },
            legend: {
              show: false,
            },
            series: [
              {
                name: 'Bilanzsumme',
                type: 'pie',
                radius: ['80%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                },
                emphasis: {
                  label: {
                    show: false,
                  }
                },
                labelLine: {
                  show: false
                },
                data: [
                  { value: 712102757, name: 'Bilanzsumme' },
                ] 
              }
            ]
          });
        }

        return
      });
    }, observeOptions);
    
    observeEchartBilanzsumme.observe(bilanzsumme);
  }

  window.onresize = function () {
    if (bilanzsumme) {
      echartBilanzsumme.resize();
    }
  };
};



export default componentChart;

